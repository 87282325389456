body {
  background: var(--primary-color);
  color: var(--text-color);
  border-color: var(--border);
  height: 100vh; 
}




/* //screen_container */
@media screen and (max-width: 480px){ 
  .screen_container{
      margin-left: auto;
      margin-right: auto;
      max-width: 460px
;    }
}

@media screen and (min-width: 481px) and (max-width: 768px){ 
  .screen_container{
    margin-left: auto;
    margin-right: auto;
      max-width: 7680px
  }
}

@media screen and (min-width: 769px) and (max-width: 1299px){ 
  .screen_container{
    margin-left: auto;
    margin-right: auto;
      max-width: 1299px
  }
}

@media screen and (min-width: 1300px){ 
  .screen_container{
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
  }
}



/* //light theme */
:root {
  --primary-color: #fff;
  --secondary-color: rgb(230, 230, 230); 
  --color-disclaimer:rgb(247, 247, 247); 

  --ter-color: rgb(220, 220, 220);
  
  --color-accent: #EE4865;  
  --input_select-b: #EE4865;  
  --input-t-select: #00000030;  

  --text-color: #212121; 

  

  --border: rgba(102, 115, 141, 0.3);  
  --border-o: rgba(171, 175, 183, 0.8);  
  --border-error: rgba(212, 67, 67, 0.79);  

  --button-color: var(--color-accent);  
  --button-color-2: #000000;  
  --disable-btn-color: #ee48646e;  

  --color-02: rgb(0, 0, 0, 0.2);
  --color-03: rgb(0, 0, 0, 0.3);
  --color-04: rgb(0, 0, 0, 0.4);
  --color-05: rgb(0, 0, 0, 0.5);
  --color-06: rgb(0, 0, 0, 0.6);
  --color-07: rgb(0, 0, 0, 0.7);
  --color-08: rgb(0, 0, 0, 0.8);
  --color-09: rgb(0, 0, 0, 0.9);
  --color-06-o: rgb(255, 255, 255, 0.6);


  --progress-bg: rgb(0, 0, 0, 0.3);
  --progress-bo: #333;

}


/* //dard theme */
.dark-mode { 
  --primary-color: #1F1F1F; 
  --secondary-color: #28292A;  
  --color-disclaimer: #28292A;  
  --ter-color: #32373c;  

  --color-accent: #EE4864;  
  --input_select-b: #EE4864;  
  --input-t-select: #ffffff30;

  --text-color: #ffffff;  

  --border: rgba(171, 175, 183, 0.8);  
  --border-o: rgba(102, 115, 141, 0.3);  
  --border-error: rgba(212, 67, 67, 0.79);  

  --button-color: var(--color-accent);  
  --button-color-2: #000000;  
  --disable-btn-color: #5d5252; 

  --color-02: rgb(255, 255, 255, 0.2);
  --color-03: rgb(255, 255, 255, 0.3);
  --color-04: rgb(255, 255, 255, 0.4);
  --color-05: rgb(255, 255, 255, 0.5);
  --color-06: rgb(255, 255, 255, 0.6);
  --color-07: rgb(255, 255, 255, 0.7);
  --color-08: rgb(255, 255, 255, 0.8);
  --color-09: rgb(255, 255, 255, 0.9);
  --color-06-o: rgb(0, 0, 0, 0.6);


  --progress-bg: rgba(255, 255, 255, 0.3);
  --progress-bo: #e8e8e8;
}


.color-primary{ background-color:  var(--primary-color); }
.color-secondary{ background-color:  var(--secondary-color); }
.color-disclaimer{ background-color:  var(--color-disclaimer); }
.color-ter{ background-color:  var(--ter-color); }
.color-accent{ background-color:  var(--color-accent); }
.text-color{ color:  var(--text-color); }
.border-color{ border-color:  var(--border); }
.border-color-o{ border-color:  var(--border-o); }

.d-shadow{ box-shadow: 0 0 3px var(--text-color); }
.c-shadow{ box-shadow: 0 0 1px var(--text-color); }
 
.color-dim-5{ color: var(--color-05); }
.color-dim-6{ color: var(--color-06); }
.color-dim-7{ color: var(--color-07); }
.color-dim-8{ color: var(--color-08); }
.color-dim-9{ color: var(--color-09); }

.font-bold-500{font-weight: 500;}
.font-bold-600{font-weight: 600;}


.blur_bg{
  backdrop-filter: blur(20px);  
  -webkit-backdrop-filter: blur(20px);  
}

































/* //item */
.item-select{
  cursor: pointer;
}

.item-select:hover{
  color: var(--color-07);
}

.item-bg{
  background-color: var(--color-02);
}

.item-bg:hover{
  background-color: var(--color-02);
  cursor: pointer;
}

.item-bg-2:hover{
  background-color: var(--color-02);
  cursor: pointer;
}



.item-bg-3{
  background-color: var(--color-02);
}

.item-bg-3:hover{
  background-color: var(--color-03);
  cursor: pointer;
}

































/* styles.css */

.slideDown {
  animation: slideDownAnimation 0.6s ease forwards;
  opacity: 1;
}

.slideUp {
  animation: slideUpAnimation 0.6s ease forwards;
}

@keyframes slideDownAnimation {
  0% {
    transform: translateY(-100%);
    visibility: visible;
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideUpAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-200%);
    visibility: hidden;
  }
}




.c_visible { animation: temp1 0.6s ease forwards; }
.c_hidden { animation: temp11 0.6s ease forwards; }
@keyframes temp1 { 0% { visibility: visible; } }
@keyframes temp11 { 100% { visibility: hidden; } }






















.progress_1{
  border: 4px solid var(--progress-bg);
  border-top: 4px solid var(--progress-bo); 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;  
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
} 

.progress_1-w{
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #e8e8e8; 
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;  
}
 







































 


/* //buton */

/* //button common part */
.btn_1 {
  width: 100%;
  height: 48px;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;

  background-color: var(--button-color);
  background-image: linear-gradient(to bottom, var(--button-color), var(--button-color2));
  box-shadow: 
  0px 2px 3px rgba(0, 0, 0, 0.2) , 
  inset 0px 1.5px 8px rgba(255, 255, 255, 0.5),
  inset 0px 1px 8px rgba(0, 0, 0, 0.1), 
  inset 0px -1px 3px rgba(0, 0, 0, 0.3);
}

/* //button disable */
.btn_1.disabled{
  cursor: not-allowed;
  color: white;
  background-color: var(--disable-btn-color);
  background-image: none;
} 




.button_small{
  min-height: 42px !important;
  min-width: 120px !important;
  width: 120px !important;
  margin-left: 15px;
}


.button_1, .button_2, .button_3{
  min-height: 45px;
  min-width: 170px;
  border-radius: 15px;
  cursor: pointer;
}
 
.button_1{
  background-color: #2c4188;
  border: 1px solid rgb(17, 51, 165)
}

.button_1:hover {
  background-color: rgb(33, 56, 131)
}

.button_2{
  background-color: rgb(41, 174, 56);
  border: 1px solid rgb(17, 165, 42)
}

.button_2:hover {
  background-color: #5aac31;
}


.button_3{
  border: 1.5px solid rgb(17, 51, 165)
}

.button_3:hover {
  background-color: rgba(17, 51, 165, 0.1)
}




























  










.custom-scrollbar{
  overflow: auto;
  position: relative; 
  border-radius: 5px;
}
.custom-scrollbar .content{
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.custom-scrollbar::-webkit-scrollbar-thumb{
  background-color: var(--scrollbar-bg);;
  border-radius: 6px;
}
.custom-scrollbar::-webkit-scrollbar-track{
  background-color: #fff0;
  border-radius: 6px;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover{
  background-color: var(--scrollbar-bg);
  border-radius: 6px;
}


.custom-scrollbar_2{
  overflow: auto;
  position: relative; 
  border-radius: 5px;
}
.custom-scrollbar_2 .content{
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.custom-scrollbar_2::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.custom-scrollbar_2::-webkit-scrollbar-thumb{
  background-color: var(--text-color);
  border-radius: 6px;
}
.custom-scrollbar_2::-webkit-scrollbar-track{
  background-color: #fff0;
  border-radius: 6px;
}
.custom-scrollbar_2::-webkit-scrollbar-thumb:hover{
  background-color: var(--text-color);
  border-radius: 6px;
}

 






























.fdfdsfs3 { 
  opacity: 0;
  animation: fadeInOut 2s infinite; }


@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}




.fdfdsfs2 { 
  opacity: 0.5;
  animation: fadeInOut2 2s infinite; }


@keyframes fadeInOut2 {
  0%, 100% {
    opacity: 0.7;
    scale: 0.9;
  }
  50% {
    opacity: 1;
    scale: 1;
  }
}


 